@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard-dynamic-subset.css");

$background: #ffffef;
$scrollColor: #f4efc9;
$scrollHoverColor: darken($scrollColor, 5%);

* {
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI",
    "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    sans-serif;
  box-sizing: border-box;
}

body {
  background: $background;
  margin: 0;
  height: 100%;
  scroll-behavior: smooth;
}

.customScroll {
  &::-webkit-scrollbar {
    background-color: $background;
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background-color: $background;
  }

  &::-webkit-scrollbar-track:hover {
    background-color: $background;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $scrollColor;
    border-radius: 2px;
    border: 3px solid $background;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $scrollHoverColor;
    border: 2px solid $background;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
}

a {
  color: #1976d2;
}
